<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>

        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-card title="Add Task Group">

                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                        <div class="alert-body">
                            {{error_message}}
                        </div>
                    </b-alert>
                
                    <b-form @submit.prevent="formSubmit">



                        <b-row>
                          <b-col md="12" >
                            
                            <b-form-group
                              label="Project Site"
                              class="required"

                            >
                              <b-form-select v-model="form.site">

                                <b-form-select-option value="" disabled>Select</b-form-select-option>
                                
                                <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                                
                                
                              </b-form-select>
                                
                            </b-form-group>
                          </b-col>

                        </b-row>

                        <b-row>
                          <b-col md="12">
                            <b-form-group
                              label="Group Name"
                              class="required"
                            >
                              <b-form-input
                                placeholder="2 to 50 characters"
                                v-model="form.group_name"
                              />
                            </b-form-group>
                          </b-col>

                          
                        </b-row>

                        <b-row class="mt-1 mb-1 margin_bottom_zero_mobile margin_top_zero_mobile">
                            <b-col md="6">
                                <b-form-group label="">
                                    <b-form-checkbox
                                        v-model="form.multiple_start"
                                        value="yes"
                                        unchecked-value="no"
                                        class="custom-control-warning"
                                    >
                                        Multiple Start Enabled
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="12">

                                <b-row>
                                  <b-col md="12" >
                                    
                                    <b-form-group
                                      label="Break Hour Set"
                                      class="required"

                                    >
                                      <b-form-select v-model="form.set_id" @change="showBreaks($event)">

                                        <b-form-select-option value="" disabled>Select</b-form-select-option>
                                        
                                        <b-form-select-option :value="set._id" v-for="set in sets" :key="set._id">{{set.set_name | capitalize}}</b-form-select-option>
                                        
                                        
                                      </b-form-select>
                                        
                                    </b-form-group>
                                  </b-col>

                                </b-row>


                                <div v-if="form.set_id != ''">
                                    <b-row>
                                        <b-col md="12">Break Hours In Set</b-col>
                                        
                                    </b-row>

                                    <b-row v-for="(fm, index) in breaks" :key="index" class="mt-1">
                                        <b-col md="12">
                                            
                                            {{fm.duration}}
                                                
                                        </b-col>
                                        
                                        
                                    </b-row>
                                    <hr>
                                </div>
                                
                                            
                                <b-row class="mt-2">
                                    <b-col>
                                        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1">
                                            Submit
                                        </b-button>

                                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.go(-1)">
                                            Cancel
                                        </b-button>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import {
        BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import vSelect from 'vue-select';

    export default {
        components: {
            BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile,BBreadcrumb
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,
                form: {
                    site:this.$route.params.site_id,
                    group_name : '',
                    set_id:'',
                    multiple_start:'no'
                },
                sites:[],
                sets:[],
                breaks:[],
            }
        },

        methods : {
            formSubmit(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form,
                        //site:this.$route.params.site_id
                    },
                    api : '/api/add-task-group'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;

                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;

                        this.successAlert().then((result) => {
                            this.$router.go(-1);
                        });
                    }
                });
            },

            /*addRow(){
                this.form.breaks.push({
                    duration : '',
                })
            },

            removeRow(index) {
                if(this.form.breaks.length > 1){
                    this.form.breaks.splice(index, 1);
                }
            },*/
            allSites(){
              return this.$store.dispatch(POST_API, {
                   data:{
                     role:this.$store.getters.currentUser.role,
                   },
                   api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.sites = data;
                        return this.sites;
                    }
                });
            },
            allSets(){
              return this.$store.dispatch(POST_API, {
                   data:{
                     //role:this.$store.getters.currentUser.role,
                   },
                   api: '/api/all-sets'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.sets = data;
                        return this.sets;
                    }
                });
            },
            showBreaks(e){
                
                this.breaks = [];

                if (e != '') {
                    this.sets.forEach(item => {
                        if (item._id == e) {
                            this.breaks = item.break;
                        }
                    })
                }
                
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:null,
                text: 'Schedule Work'
              },{
                to:{name:'task-groups'},
                text: 'Group Setting',
                
              },{
                to:null,
                text: 'Add Task Group',
              }];
              return item;
            }
        },
        mounted(){
            this.allSites();
            this.allSets();
        }
    }
</script>
